.order-card {
	border-radius: 8px;
	border: solid 1px var(--border-color);
	width: 75%;
	box-sizing: border-box;
	padding: 10px;
	display: flex;
	float: left;
	height: 80px;
}

.order-card-left {
	text-align: left;
	padding: 5px;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap:5px
}

.order-card-right {
	width: 100%;
	flex: 1;
	padding: 1px;
	text-align: right;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap:6px;
}

.order-card-order-number {
	font-weight: 600;
	font-size: 16px;
	color: #28333E;
}

.order-card-order-info {
	font-size: 14px;
	font-weight: 500;
	color: #28333E;
}

.order-card-customer-name {
	margin-right: 5px;
}

.order-card-number-of-items {
	margin-left: 5px;
}

.order-card-time-of-arrival {
	font-size: 16px;
	font-weight: 600;
	color: var(--navbar-color);
	text-align: right;
}

.order-card-set-eta {
	display: flex;
	font-family: 'Plus Jakarta Sans';
	font-style: normal;
	align-items: center;
	color: #EC4F58;
	font-weight: 500;
	font-size: 14px;
	white-space: nowrap;
	text-align: right;
}

.order-card-completed {
	display: flex;
	font-family: 'Plus Jakarta Sans';
	font-style: normal;
	align-items: center;
	color: #65B02A;
	font-weight: 500;
	font-size: 14px;
	white-space: nowrap;
	text-align: right;
	margin-top: 9px;
	gap: 3px;
}