.restaurant-image-container {
	width: 100%;
	max-height: 200px;
	overflow: hidden;
	margin: 0 auto;
	position: relative;
}

.restaurant-image {
	width: 100%;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.restaurant-image-MenuApp-user-facing-app {
  width: 100%;
}
