@import url("https://fonts.googleapis.com/css2?family=Anek+Telugu&display=swap");

.search-bar-body-menu-app {
	font-family: 'Anek Telugu', sans-serif;
	font-size: 18px;
	border-radius: 8px;
	height: 40px;
	width: 100%;
	max-width: 500px;
	border-color: transparent;
	margin: 0 auto;
	margin-bottom: 5px;
	margin-top: 10px;
}

.search-bar-input-menu-app {
	font-family: 'Anek Telugu', sans-serif;
	font-size: 18px;
	/*border-radius: 25px;*/
	border-radius: 8px;
	height: 100%;
	box-sizing:border-box;
	width: 100%;
	margin-right: 50px;
	padding-left: 50px;
	border: none;
	background: rgb(245, 245, 245);
	font-weight: 700px;
	padding-top: 6.5px;
	color: black;
	height: 44px;
}

.search-bar-menu-app-search-icon {
	color: rgb(77, 77, 77);
	position: relative;
	top: -30px;
	left: 20px;
}

.search-bar-menu-app-search-icon-restaurant-facing-app-kitchen {
	color: rgb(77, 77, 77);
	position: relative;
	top: -30px;
	left: -220px;
}

.search-bar-menu-app-search-icon-restaurant-facing-app-admin {
	color: rgb(77, 77, 77);
	position: relative;
	top: -30px;
	left: -220px;
}

.search-bar-menu-app-search-icon-user-facing-app {
	color: rgb(77, 77, 77);
	position: relative;
	top: -30px;
	left: -220px;
}

.search-bar-input-menu-app::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(77, 77, 77);
  opacity: 1; /* Firefox */
}

.search-bar-input-menu-app:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgb(77, 77, 77);
}

.search-bar-input-menu-app::-ms-input-placeholder { /* Microsoft Edge */
  color: rgb(77, 77, 77);
}

.search-bar-menu-app-voice-search-button {
	border-radius: 100%;
	background: var(--logo-color);
	background: transparent;
	width: 44px;
	height: 44px;
	float: right;
	display: inline;
	margin-top: -33px;
	border: none;
	z-index: 150;
	position: relative;
	left: -100%;
	color: rgb(77, 77, 77);
	height: 20px;
}

.search-bar-menu-app-clear-search-button {
	border-radius: 8px;
	color: rgb(77, 77, 77);
	/*background: var(--logo-color);*/
	background: transparent;
	width: 44px;
	height: 44px;
	float: right;
	display: inline;
	margin-top: -45px;
	border: none;
	z-index: 150;
	position: relative;
	left: 0%;
	font-size: 30px;
	font-weight: 400;
}