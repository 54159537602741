.business-display {
    display: block;
    margin-top: 18px;
    margin-bottom: 18px;
}

/* Business Image */
.business-display-image{
    width: 130px;
    height: 130px;
    display: inline-block;
    background: #EBEBEB;    
    border-radius: 8px;
    justify-content: center;
    align-items: center;
}
.business-display-image-text{
    color: #525F6A;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media only screen and (max-width: 768px) {
    .business-display {
        align-items: center;
        width: 90%;
    }

    /* Business Image */
    .business-display-image{
        margin-bottom: 30px;
    }

}