.search-bar-input {
	color:  #838B93;
	border-radius: 8px;
	padding-left: 10px;
	padding-right: 10px;
	border: 1px solid var(--border-color);
	margin-top: 25px;
	height: 40px;
	font-size: 14px;
	width: 65%;
	font-family: 'Plus Jakarta Sans';
	font-weight: 500;
}

.search-bar-input::placeholder {
	color: var(--placeholder-color);
	font-weight: 500;
}
