.business-table-label{
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #838B93;
    margin-bottom: 6px;
    text-align: left;
}
.business-table-input {
    height: 40px;
    width: 90%;
    max-width: 500px;
    border-radius: 8px;
    border: 1px solid #EBEBEB;

    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding-left: 15px;
    padding-right: 26px;

    text-overflow: ellipsis;
    overflow: hidden;
    font-family: 'Plus Jakarta Sans';
}

.business-table-input:focus {
    outline: none;
    border: 1px solid #EBEBEB;
}

.business-table-cell {
    display: block;
    width: 100%;
}