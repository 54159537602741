.admin-navigation-button{
    width: 100%;
    height: 46px;
    
    text-align: center;
    background: #28333E;
    border-radius: 6px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.admin-navigation-button-icon{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-right: 10px;
    padding-top: 4px;
}

.admin-navigation-button-text{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}