.orders-aggregated {
	width: 100%;
	text-align: center;
	padding-left: 30px;
	padding-right: 30px;
	box-sizing: border-box;
	height: 260px;
	border-right: solid 1px var(--border-color);
	z-index: 2;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.orders-aggregated-header {
	color: var(--navbar-color);
	margin-top: 25px;
	font-size: 28px;
	font-weight: 600;
}

.orders-aggregated-containers-spacer {
	height: 30px;
	width: 100%;
	color: black;
}

.orders-aggregated-containers {
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;

	overflow-y: scroll;
	top: 352px;
	bottom: 0px;
	position: absolute;
	width: 80%;
	z-index: 1;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    padding-right: 11px;
    margin-top: 20px;
}

.orders-aggregated-containers::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.orders-aggregated-container {
	margin-bottom: 25px;
	height: 105px;
	width: 50%;
}

.orders-aggregated-card-spacer {
	height: 100px;
}

.orders-aggregated-card-placeholder {
	text-align: center;
	margin-top: 100px;
	width: 100%;
}

.aggregated-search {
    display: block;
    width: 50%;
    margin: 0 auto;
}