.close-button {
  border-radius: 25px;
  border: none;
  padding: 12px;
  padding-top: 0px;
  padding-bottom: 0px;
  cursor: pointer;
  color: black;
  font-size: 40px;
  z-index: 30;
  position: sticky;
  left: 8px;
  top: 8px;
  background: white;
}