@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

html {
  text-align: center;
  --navbar-color: #1a2228;
  --border-color: #ebebeb;
  --placeholder-color: #838B93;
  --secondary-text-color: #525F6A;
  overflow: visible;
}

.App {
	font-family: 'Plus Jakarta Sans';
}

.body-modal-open {
	overflow-x: hidden;
}

.body {

}

.switch-device-disclaimer {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 80%;
}