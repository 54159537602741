.employee-content{
    position: absolute;    
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 574px;
    top: 90px;
    color: #28333E;
    text-align: center;   
}

.employee-content-button{
    display: flex;
    justify-content: end;
    width: 870px;
}

.employee-content-info {
    border: 1px solid #EBEBEB;
    box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.02);
    border-radius: 10px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40px;

    /* position: absolute; */
    width: 870px;
    height: 288px;
    margin-top: 13px;
}