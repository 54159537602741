.change-order-status-button {
	box-sizing: border-box;
	border: solid 1px var(--border-color);
	border-radius: 8px;
	float: right;
	display: inline-block;
	width: 20%;
	margin-right: 3%;
	text-align: center;
	height: 105px;
	padding: 10px;
	font-size: 12px;
	color: var(--secondary-text-color);
	font-weight: 600;
}

.change-order-status-image {
	width: 45px;
	margin-top: 6px;
	margin-bottom: 0px;
}