.business-button {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 344px;
    height: 38px;
    background: hsl(210, 22%, 20%);
    border-radius: 6px;
}

.business-button-text{
    color: #FFFFFF;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    text-align: center;
}

@media only screen and (max-width: 768px) {
    .business-button {
        margin-bottom: 20px;
        height: 40px;
    }
}
