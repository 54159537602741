/* Headers */
.employee-content-info-head {
    margin-top: 30px;
    margin-bottom: 13px;
    display: flex;
}
.employee-content-info-head-head {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
} 
.employee-content-info-head-icon {
    margin-left: 10px;
    color: #838B93;
    padding-top: 2px;
}

/* Wrappers */
.employee-content-info-head-wrapper-name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 290px;
}
.employee-content-info-head-wrapper-screen {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 290px;
    width: 300px;
}
.employee-content-info-head-wrapper-role {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 290px;
}

.employee-content-info-wrapper {
    margin-top: 13px;
}

/* Search bars */
.employee-content-info-search {
    border: 1px solid #EBEBEB;
    box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.02);
    border-radius: 10px;
    padding: 9px;
    width: 90%;
    display: flex;
}
.employee-content-search {
    border: none;
}
.employee-content-search:focus {
    outline: none;
}
.employee-content-search::placeholder {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #838B93;
}
.employee-content-info-search-icon {
    margin-right: 10px;
    color: #838B93;
}

/* Text */
.employee-content-info-name {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 12px;
    margin-top: 0;
    display: flex;
}
.employee-content-info-role {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 12px;
    margin-top: 0;
    display: flex;
}
.employee-content-info-screen {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 12px;
    margin-top: 0;
    display: flex;
}
