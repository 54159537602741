.admin-content-header {
    flex: auto;
    width: 500px;
}
.admin-content-navigation {
    flex: auto;
    width: 300px;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
    