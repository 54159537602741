.item-card {
    width: 200px;
    height: 231px;
    color: black;
    margin-left: 25px;
    margin-top: 30px;

    background: #FFFFFF;

    border: 1px solid #EBEBEB;
    box-shadow: 0px 1px 30px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
}

/* Station color */
.entree {
    border-top: 3px solid #CAFF95;
}
.dessert {
    border-top: 3px solid #95FFFF;
}

.item-card-title {
    margin-top: 9px;
    margin-bottom: 3px;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
}

.item-card-order-number {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.02em;

    color: #28333E;
    opacity: 0.6;
}

/* Options */
.item-card-option-title {
    margin: 0;
    text-align: start;

    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;

    color: #28333E;
}

.item-card-option-value {
    margin: 0;
    text-align: start;

    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;

    color: #838B93;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
}

.item-card-option {
    margin-bottom: 6px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.item-card-option-container {
    margin-top: 10px;
    margin-left: 15px;
    width: 160px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
}