.change-order-status-in-modal-button {
	box-sizing: border-box;
	border: solid 1px var(--border-color);
	border-radius: 8px;
	width: 60px;
	height: 60px;
	text-align: center;
	vertical-align: center;
	font-size: 12px;
	color: var(--secondary-text-color);
	display: inline-block;
	margin-left: 20px;
	margin-right: 20px;
}

.change-order-status-in-modal-image {
	height: 20px;
	margin-top: 50%;
	transform: translate(0%, -50%);
	margin-left: 5px;
}

.change-order-status-in-modal-text {
	display: inline-block;
	margin-left: 5px;
	margin-bottom: 5px;
}