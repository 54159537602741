.employee-checkbox-label {
    border-radius: 8px;
    border: 1px solid #28333E;
    width: 140px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
} 
