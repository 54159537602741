/* Table for hours */
.business-table-hours {
    display: block;
}

/* Individual Hour block */
.business-hour {
    display: block;
    text-align: left;
    margin-bottom: 10px;
    margin-right: 25px;
    justify-content: space-between;
}
.business-hour-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    display: inline-block;
    width: 50px;
    text-align: left;
}
.business-table-hour-inputs {
    display: inline-block;
}
.business-table-hour-open-status-dropdown {
    height: 40px;
    width: 120px;
    border-radius: 8px;
    border: 1px solid #EBEBEB;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding-left: 7.5px;
    margin-left: 5px;
    margin-right: 5px;
    font-family: 'Plus Jakarta Sans';
}
.business-table-hour-input {
    height: 40px;
    width: 45px;
    border-radius: 8px;
    border: 1px solid #EBEBEB;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding-left: 7.5px;
    margin-left: 5px;
    margin-right: 5px;
    font-family: 'Plus Jakarta Sans';
}
.business-table-hour-input:focus {
    outline: none;
    border: 1px solid #AA95B6;
}
.business-table-hour-ampm-dropdown {
    height: 40px;
    width: 55px;
    border-radius: 8px;
    border: 1px solid #EBEBEB;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding-left: 7.5px;
    margin-left: 5px;
    margin-right: 5px;
    font-family: 'Plus Jakarta Sans';
}