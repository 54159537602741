.employee-navigation-button{
    width: 200px;
    height: 40px;
    
    text-align: center;
    background: #28333E;
    border-radius: 6px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.employee-navigation-button-icon{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-right: 10px;
    padding-top: 4px;
}

.employee-navigation-button-text{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}