.overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.ModalCheckout-wide {
  position: fixed;
  z-index: 20;
  background: #fff;
  width: 100%;
  height: 95%;
  max-width: 600px;
  overflow: scroll;
  border-radius: 25px;
  /* Center the ModalCheckout */
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: left;
}

.ModalCheckout-wide::-webkit-scrollbar {
  display: none;
}

.ModalCheckout-narrow {
  position: fixed;
  z-index: 20;
  background: #fff;
  width: 100%;
  height: 100%;
  max-width: 500px;
  /* Center the ModalCheckout */
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  overflow: scroll;
  text-align: left;
}

.ModalCheckout-narrow::-webkit-scrollbar {
  display: none;
}

.ModalCheckout-header {
  /*background: orangered;*/
  /*padding: 10px 20px;*/
  display: block;
  justify-content: space-between;
  height: 40px;
}

.ModalCheckout-title {
  margin-top: 10px;
}

.ModalCheckout-main {
  padding: 7%;
  padding-top: 0;
  padding-bottom: 0;
  min-height: 77%;
}

.button {
  background: green;
  border: none;
  padding: 10px 20px;
  color: #fff;
  cursor: pointer;

}

.button:hover {
  background: orange;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ModalCheckout-input {
    font-family: 'Anek Telugu', sans-serif;
    font-size: 18px;
    border-radius: 8px;
    height: 100%;
    box-sizing: border-box;
    width: 100%;
    border: none;
    background: rgb(245, 245, 245);
    font-weight: 700px;
    padding-top: 6.5px;
    padding-left: 11px;
    padding-right: 11px;
    color: black;
    height: 44px;
}

.ModalCheckout-input-label {
    font-family: 'Anek Telugu', sans-serif;
    font-size: 18px;
    font-weight: 700px;
    color: black;
    margin-bottom: 10px;
}

.ModalCheckout-close-button {
  font-size: 1.5em;
  font-weight: 700;
  font-family: 'Anek Telugu', sans-serif;
  padding: 16px;
  padding-top: 0px;
  padding-bottom: 0px;
  cursor: pointer;
  z-index: 30;
  margin-left: 8px;
  margin-top: 24px;
  background: transparent;
  border: none;
}

.ModalCheckout-chevron-left {
  width: 22px;
}


.ModalCheckout-phone-number {
  margin-bottom: 40px;
}