/* Modal Overlay */
.overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .7);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
}
.add-employee-modal-wide{
    position: fixed;
    z-index: 20;
    background: #fff;
    width: 100%;
    height: 80%;
    max-width: 600px;
    border-radius: 25px;
    overflow: scroll;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    padding-bottom: 20px;
}
.add-employee-modal-narrow{
    position: fixed;
    z-index: 20;
    background: #fff;
    width: 100%;
    height: 100%;
    max-width: 500px;
    border-radius: 25px;
    overflow: scroll;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    padding-bottom: 20px;
}
.close-button {
    border-radius: 25px;
    border: none;
    padding: 12px;
    padding-top: 0px;
    padding-bottom: 0px;
    cursor: pointer;
    color: black;
    font-size: 40px;
    z-index: 30;
    position: sticky;
    background: white;
    margin-right: 85%;
}

/* Employee table */
.add-employee-table{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    height: inherit;
    
}
.add-employee-table-label{
    font-weight: 600;
    font-size: 17px;
    line-height: 18px;
    margin-bottom: 6px;
    margin-top: 6px;
}

.add-employee-table-label-container{
    display: flex;
    width: 67%;
}

/* Input */
.add-employee-table-input {
    height: 34px;
    width: 60%;
    border-radius: 8px;
    border: 1px solid #EBEBEB;

    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding-left: 15px;
    padding-right: 26px;

    text-overflow: ellipsis;
    overflow: hidden;
}
.add-employee-table-input:focus {
    outline: none;
    border: 1px solid #AA95B6;
}

/* Select box */
.add-employee-table-select{
    width: 67%;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #EBEBEB;

    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding-left: 13px;
    padding-right: 26px;
}
.add-employee-table-select:focus {
    outline: none;
    border: 1px solid #AA95B6;
}

/* Screen Selection */
.add-employee-table-screens{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    height: 100px;
  
}