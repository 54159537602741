.screen-toggle {
    height: 50px;
    width: 100%;
    background-color: #F7F7F7;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


