.view-cart-button-wide {
	background: var(--logo-color);
	/*width: 500px;
	left: 50%;
    transform: translate(-50%, -50%);*/
	width: 45%;
	margin-left: 27.5%!important;
	height: 50px;
	border-radius: 25px;
	border: none;
    position: sticky;
    position: -webkit-sticky;
    bottom: 20px;
    display: block;
}

.view-cart-button-wide-restaurant-facing-app-kitchen {
	background: var(--navbar-color);
	width: 45%;
	height: 50px;
	border-radius: 25px;
	border: none;
    position: sticky;
    position: -webkit-sticky;
    bottom: 20px;
    z-index: 2;
}

.view-cart-button-wide-restaurant-facing-app-admin {
	background: var(--navbar-color);
	width: 45%;
	height: 50px;
	border-radius: 25px;
	border: none;
    position: sticky;
    position: -webkit-sticky;
    bottom: 20px;
    z-index: 2;
}

.view-cart-button-wide-user-facing-app {
	background: var(--logo-color);
	width: 45%;
	height: 50px;
	border-radius: 25px;
	border: none;
    position: sticky;
    position: -webkit-sticky;
    bottom: 65px;
}

.view-cart-button-narrow {
	background: var(--logo-color);
	width: 100%;
	height: 50px;
	border-radius: 25px;
	border: none;
    position: sticky;
    bottom: 20px;
    display: block;
}

.view-cart-button-narrow-user-facing-app {
	background: var(--logo-color);
	width: 100%;
	height: 50px;
	border-radius: 25px;
	border: none;
    position: sticky;
    bottom: 65px;
}

.view-cart-span {
	color: white;
	font-weight: 700;
	font-family: 'Anek Telugu', sans-serif;
	padding-top: 4px;
	font-size: 18px;
}