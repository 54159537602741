.item-page {
    width: 100%;
    height: auto;
}

.item-page-title {
    margin-top: 20px;
    margin-bottom: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #000000;
}

.item-page-search {   
    display: block;
    width: 40%;
}

.item-page-head {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 94%;
}

.item-page-select {
    width: 250px;
    height: 44px;

    border-radius: 8px;
	padding-left: 10px;
	padding-right: 10px;
	border: 1px solid var(--border-color);
	font-size: 14px;
    color: #838B93;
}

.item-page-items {
    z-index: 0;
    display: inline-flex;
    flex-wrap: wrap;
    align-content: flex-start;

    width: 72.5em;
    overflow-y: scroll;
    top: 240px;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    margin: 0 auto;

    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.item-page-items::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
