.content-header-head {
    height: 25px; 
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 25px;
    margin-bottom: 0;
}

.content-header-text{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;    
    color: #525F6A;
}

.header-content{
    position: absolute;    
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 574px;
    top: 90px;
    color: #28333E;
    text-align: center;   
}