.screen-toggle-button {
    display: flex;
    height: 31px;
    color: #525F6A;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
    padding: 0 15px 0 15px;
}

.active-screen {
    color: #28333E;
    background-color: rgba(74, 109, 141, 0.1);
    border-radius: 25px;
}

.screen-toggle-text {
    font-weight: 500;
    font-size: 15px;
}