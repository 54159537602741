.content{
    position: absolute;    
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    top: 90px;
    height: calc(100% - 90px);
    overflow-y: scroll;
    color: #28333E;
    text-align: center;
}