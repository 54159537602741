.navbar {
	background: var(--navbar-color);
	width: 100%;
	height: 70px;
	position: sticky;
	position: -webkit-sticky;
	top: 0;
	vertical-align: center;
	z-index: 3;
}

.navbar-hamburger {
	float: left;
	margin-left: 20px;
	margin-top: 20px;
	height: 30px;
	width: 45px;
}

.navbar-disclaimer {
	position: absolute;
	color: white;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.navbar-kitchen-item-availability {
	background: white;
	padding: 10px 14px;
	border-radius: 8px;
	width: 140px;
	height: 40px;
	border: none;
	font-weight: 600;
	font-size: 14px;
	font-family: 'Plus Jakarta Sans';
	font-style: normal;
	position: absolute;
	right: 14px;
	top: 50%;
	transform: translate(0%, -50%);
}


/*
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 10px 14px;
gap: 10px;

width: 128px;
height: 40px;

background: #FFFFFF;
border-radius: 8px;

flex: none;
order: 0;
flex-grow: 0;*/