.business-content {
    position: absolute;    
    display: block;
    align-items: center;
    width: 100%;
    top: 90px;
    color: #28333E;
    text-align: center;   
}

.business-button-sticky {
	position: sticky;
	bottom: 0px;
}