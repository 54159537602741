.orders-aggregated-card {
	border-radius: 8px;
	border: solid 1px var(--border-color);
	width: 75%;
	/*width: 100%;*/
	box-sizing: border-box;
	padding: 20px;
	display: flex;
	float: left;
	height: 105px;
}

.orders-aggregated-card-left {
	text-align: left;
	width: 100%;
}

.orders-aggregated-card-right {
	width: 100%;
	flex: 1;
}

.orders-aggregated-card-dish-header {
	font-weight: 600;
	font-size: 16px;
	color: var(--navbar-color);
	margin-bottom: 8px;
}

.orders-aggregated-card-order-info {
	font-size: 14px;
	font-weight: 500;
	color: var(--secondary-text-color);
}

.orders-aggregated-card-customer-name {
	margin-right: 5px;
}

.orders-aggregated-card-number-of-items {
	margin-left: 5px;
}

.orders-aggregated-card-time-of-arrival {
	font-size: 16px;
	font-weight: 600;
	color: var(--navbar-color);
	margin-bottom: 8px;
}

.orders-aggregated-card-set-eta {
	color: red;
	font-weight: 600;
	font-size: 14px;
	text-overflow: nowrap;
}