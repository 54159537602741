.overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.ModalOrders-wide {
  position: fixed;
  z-index: 20;
  background: #fff;
  width: 100%;
  height: 80%;
  max-width: 600px;
  border-radius: 25px;
  overflow: scroll;
  /* Center the ModalOrders */
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: left;
  font-weight: 600;
  color: var(--navbar-color);
}

.ModalOrders-wide::-webkit-scrollbar {
  display: none;
}

.ModalOrders-narrow {
  position: fixed;
  z-index: 20;
  background: #fff;
  width: 100%;
  height: 100%;
  max-width: 500px;
  overflow: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  /* Center the ModalOrders */
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: left;
  font-weight: 600;
  color: var(--navbar-color);
}

.ModalOrders-narrow::-webkit-scrollbar {
  display: none;
}

.ModalOrders-header-wide {
  display: block;
  justify-content: space-between;
  margin-top: -46px;
}

.ModalOrders-header-narrow {
  display: block;
  justify-content: space-between;
  margin-top: -54px;
}

.ModalOrders-title {
  margin-top: 30px;
}

.ModalOrders-main {
  padding-top: 7%;
  padding-left: 7%;
  padding-right: 7%;
}

.button {
  background: green;
  border: none;
  padding: 10px 20px;
  color: #fff;
  cursor: pointer;

}

.button:hover {
  background: orange;
}

.close-button {
  border-radius: 25px;
  border: none;
  padding: 16px;
  padding-top: 0px;
  padding-bottom: 0px;
  cursor: pointer;
  color: var(--navbar-color);
  font-size: 40px;
  z-index: 30;
  position: sticky;
  left: 8px;
  top: 8px;
  background: transparent;
}

.ModalOrders-label {
  color: var(--secondary-text-color);
  margin-right: 10px;
}

.ModalOrders-customer {
  font-size: 18px;
}

.ModalOrders-number-of-items {
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 18px;
}

.ModalOrders-item {
  margin-top: 15px;
}

.ModalOrders-item-title-line {
  font-size: 18px;
  font-weight: 800;
}

.ModalOrders-item-option {
  color: var(--secondary-text-color);
  margin-top: 5px;
  font-weight: 700;
}

.ModalOrders-item-option-value {
  color: var(--secondary-text-color);
  font-weight: 400;
}

.change-order-status-in-modal-button-container {
  text-align: center;
  position: sticky;
  bottom: 0px;
  background: white;
  padding-bottom: 20px;
  padding-top: 20px;
  overflow-y: hidden;
}