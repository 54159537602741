.business-form {
    width: 90%;
    max-width: 500px;
}
.business-table {
    display: block;
    justify-content: space-around;
}

/* First table */
.business-table-one {
    display: block;
    align-items: start;
}

/* Table two */
.business-table-two {
    display: block;
    align-items: start;
}
.business-table-zip {
    display: block;
    width: 98%;
    justify-content: space-between;
}
.business-table-zip-holder {
    display: block;
    align-items: start;
    width: 90px;
    margin-right: 10px;
}
.business-table-state-holder {
    display: block;
    align-items: start;
    width: 90px;
}

/* Table three */
.business-table-three {
    display: block;
    align-items: start;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .business-table {
    }

    /* First table */
    .business-table-one {
        width: 99%;
    }

    /* Table two */
    .business-table-two {
        width: 99%;
    }
    .business-table-zip {
        display: block;
        width: 98%;
        justify-content: space-between;
    }
    .business-table-zip-holder {
        width: 40%;
        margin-right: 5%;
    }
    .business-table-state-holder {
        width: 40%;
    }

    /* Table three */
    .business-table-three {
        width: 350px;
    }
}